<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-card>
      <div class="w-100 d-flex align-items-center justify-content-between">
        <h1>ویرایش خدمات</h1>
        <b-button
          size="sm"
          type="button"
          variant="primary"
          v-b-modal.modal-create
        >
          افزودن خدمت جدید
        </b-button>
      </div>
      <b-row align-content="center" v-if="settingDetails">
        <b-col cols="12">
          <div
            class="w-100 d-flex align-items-center justify-content-center"
            v-if="
              !settingDetails.ourServices ||
              settingDetails.ourServices.length == 0
            "
          >
            <p class="text-center">هیچ خدمتی جهت نمایش وجود ندارد</p>
          </div>
          <div v-else>
            <b-row>
              <b-col
                cols="12"
                md="3"
                class="my-1 cursor-pointer"
                v-for="slider in settingDetails.ourServices"
                :key="slider.priority"
                @click="renderImageModal(slider)"
              >
                <span v-html="findSelectedSvg(slider.imageUrl)"></span>
                <p class="mt-2">{{ slider.title }}</p>

                <!-- <b-img
                  class="cursor-pointer"
                  @click="renderImageModal(slider)"
                  :src="handleImageUrl(slider)"
                  alt=""
                  style="object-fit: object-contain"
                  height="auto"
                  width="200"
                /> -->
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <div class="w-100 d-flex align-items-center justify-content-end">
        <b-button variant="success" v-b-modal.modal-create>
          افزودن خدمت جدید
        </b-button>
      </div>
    </b-card>
    <b-modal
      id="modal-create"
      centered
      size="lg"
      ok-variant="success"
      title="افزودن خدمت"
      ok-title="افزودن"
      cancelTitle="انصراف"
      @ok="pushNewSlider"
    >
      <b-container fluid>
        <b-form-row>
          <b-col cols="12">
            <small>آیکون خدمت</small>
          </b-col>
          <b-col
            v-for="(icon, index) in iconsList"
            :key="index"
            cols="4"
            class="d-flex align-items-center justify-content-center h-100 cursor-pointer"
            style="gap: 3rem"
            @click="setTempIcon(icon)"
          >
            <span
              :class="tempIcon == icon.componentName ? 'selected-svg' : ''"
              v-html="icon.svg"
            ></span>
          </b-col>
          <b-col cols="12">
            <b-form @submit.prevent>
              <b-form-group label="عنوان خدمت" label-for="title">
                <b-form-input
                  id="title"
                  placeholder="کارگزار تمام خدمات بازار سرمایه"
                  v-model="newSliderDetails.title"
                ></b-form-input>
              </b-form-group>
            </b-form>
          </b-col>
          <b-col cols="12">
            <b-form @submit.prevent>
              <b-form-group label="توضیحات خدمت" label-for="description">
                <b-form-textarea
                  id="description"
                  placeholder="شرکت کارگزاری ستاره جنوب، کارگزار رسمی بورس و ..."
                  v-model="newSliderDetails.description"
                ></b-form-textarea>
              </b-form-group>
            </b-form>
          </b-col>
          <b-col cols="12">
            <small>دکمه خدمت</small>
            <hr />
            <b-row>
              <b-col cols="6">
                <b-form @submit.prevent>
                  <b-form-group label="متن دکمه" label-for="linkName">
                    <b-form-input
                      v-model="newSliderDetails.linkName"
                      id="linkName"
                      placeholder="ادامه مطلب"
                    ></b-form-input>
                  </b-form-group>
                </b-form>
              </b-col>
              <b-col cols="6">
                <b-form @submit.prevent>
                  <b-form-group label="لینک دکمه" label-for="link">
                    <b-form-input
                      v-model="newSliderDetails.link"
                      id="link"
                      placeholder="https://sjbourse.ir/صندوق-تمشک"
                    ></b-form-input>
                  </b-form-group>
                </b-form>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12">
            <div
              class="w-100 d-flex align-items-center justify-content-center my-2"
            >
              <b-img
                v-if="imagePreview"
                :src="imagePreview"
                height="auto"
                width="400"
                class="rounded"
              />
            </div>
          </b-col>
        </b-form-row>
      </b-container>
    </b-modal>
    <slider-image-modal
      :sliderDetails="tempImage"
      :isSvg="true"
      @refetch="getSetting"
      @loading="loading"
      @loaded="loaded"
    ></slider-image-modal>
  </b-overlay>
</template>
<script>
export default {
  title: "تنظیمات خدمات",
  mounted() {
    this.getSetting();
  },
  data() {
    return {
      isLoading: false,
      iconsList: [
        {
          svg: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style="width: 3rem; height: 3rem"> <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z" /> </svg>',
          componentName: "BarChartIcon",
        },
        {
          svg: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style="width: 3rem; height: 3rem"> <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" /> </svg>',
          componentName: "CashIcon",
        },
        {
          svg: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style="width: 3rem; height: 3rem"> <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" /> </svg>',
          componentName: "PresentationChartIcon",
        },
        {
          svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="43" height="43" > <path fill="none" d="M0 0H24V24H0z" /> <path d="M5 3v16h16v2H3V3h2zm15.293 3.293l1.414 1.414L16 13.414l-3-2.999-4.293 4.292-1.414-1.414L13 7.586l3 2.999 4.293-4.292z" fill="rgba(110,107,123,1)" /> </svg>',
          componentName: "LineChartIcon",
        },
        {
          svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="43" height="43" > <path fill="none" d="M0 0h24v24H0z" /> <path d="M2 4h2v16H2V4zm4 0h1v16H6V4zm2 0h2v16H8V4zm3 0h2v16h-2V4zm3 0h2v16h-2V4zm3 0h1v16h-1V4zm2 0h3v16h-3V4z" fill="rgba(110,107,123,1)" /> </svg>',
          componentName: "BarcodeIcon",
        },
        {
          svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="43" height="43" > <path fill="none" d="M0 0h24v24H0z" /> <path d="M11 2.05V13h10.95c-.501 5.053-4.765 9-9.95 9-5.523 0-10-4.477-10-10 0-5.185 3.947-9.449 9-9.95zm2 0A10.003 10.003 0 0 1 21.95 11H13V2.05z" fill="rgba(110,107,123,1)" /> </svg>',
          componentName: "PieChartIcon",
        },
      ],
      settingDetails: null,
      newSliderDetails: {
        indexSliderId: 0,
        title: null,
        link: null,
        linkName: null,
        description: null,
        priority: 0,
        type: 4, // 4 is for services
        imageUrl: null,
        createDate: new Date(Date.now()).toISOString(),
      },
      fileDetails: {
        base64: null,
        objectId: 0,
        type: 10, // 10 is for library
        priority: null,
      },
      libraryBase64: null,
      imagePreview: null,
      tempImage: null,
      tempIcon: null,
    };
  },
  methods: {
    loading() {
      this.isLoading = true;
    },
    loaded() {
      this.isLoading = false;
    },
    findSelectedSvg(svgName) {
      let findedSvg = this.iconsList.find(
        (item) => item.componentName == svgName
      );
      if (findedSvg) {
        return findedSvg.svg;
      }
    },
    setTempIcon(icon) {
      this.tempIcon = icon.componentName;
    },
    async createNewSlider() {
      try {
        let _this = this;
        let createNewIndexSlider = new CreateNewIndexSlider(_this);
        createNewIndexSlider.setRequestParamDataObj(_this.newSliderDetails);
        await createNewIndexSlider.fetch((response) => {
          if (response.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: `عملیات موفق`,
                icon: "CheckIcon",
                variant: "success",
                text: `فایل آپلود شد.`,
              },
            });
            _this.getSetting();
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    async getSetting() {
      try {
        this.tempImage = null;
        this.newSliderDetails = {
          indexSliderId: 0,
          title: null,
          link: null,
          linkName: null,
          description: null,
          priority: 0,
          type: 4, // 4 is for services
          imageUrl: null,
          createDate: new Date(Date.now()).toISOString(),
        };
        this.isLoading = true;
        let _this = this;
        let getASingleSetting = new GetASingleSetting(_this);
        getASingleSetting.setRequestParam({ id: 1 });
        await getASingleSetting.fetch((response) => {
          if (response.isSuccess) {
            _this.settingDetails = response.data;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async updateSetting() {
      try {
        this.isLoading = true;
        let _this = this;
        let updateASetting = new UpdateASetting(_this);
        updateASetting.setRequestParamDataObj(_this.settingDetails);
        await updateASetting.fetch((response) => {
          if (response.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: `عملیات موفق`,
                icon: "CheckIcon",
                variant: "success",
                text: `خدمات بروز شد.`,
              },
            });
            _this.getSetting();
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async sendCreateFileRequest() {
      try {
        this.isLoading = true;
        let _this = this;
        let createFile = new CreateFile(_this);
        createFile.setRequestParamDataObj(_this.fileDetails);
        await createFile.fetch((response) => {
          if (response.isSuccess) {
            _this.newSliderDetails.imageUrl = response.data.imageName;
            _this.createNewSlider();
            _this.imagePreview = null;
            _this.fileDetails = {
              base64: null,
              objectId: 0,
              type: 10, // 10 is for library
              priority: null,
            };
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    pushNewSlider() {
      this.newSliderDetails.priority =
        this.settingDetails.ourServices?.length > 0
          ? this.settingDetails.ourServices[
              this.settingDetails.ourServices?.length - 1
            ].priority + 1
          : 1;
      this.newSliderDetails.imageUrl = this.tempIcon;
      this.createNewSlider();
      //   this.sendCreateFileRequest();
    },
    renderImageModal(slider) {
      this.tempImage = JSON.parse(JSON.stringify(slider));
      this.$bvModal.show("details-modal");
    },
    handleImageUrl(slider) {
      if (slider.imageUrl) {
        return `${Helper.baseUrl}/Media/Gallery/Library/${slider.imageUrl}`;
      }
    },
    async makeBase64Pic() {
      const _this = this;
      let file = _this.$refs.refInputEl.files[0];
      _this.imagePreview = URL.createObjectURL(file);
      const result = await _this.readAsDataURL(file);
      _this.fileDetails.base64 = result;
    },
    async readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onerror = reject;
        fr.onload = () => {
          resolve(fr.result.split(",")[1]);
        };
        fr.readAsDataURL(file);
      });
    },
  },
  components: {
    BContainer,
    BPagination,
    BFormRow,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BTable,
    BMediaAside,
    BMediaBody,
    BForm,
    BFormCheckbox,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BFormFile,
    BLink,
    BButton,
    BBadge,
    BFormTextarea,
    BOverlay,
    BFormSelect,
    BModal,
    SliderImageModal,
  },
};
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { GetASingleSetting, UpdateASetting } from "@/libs/Api/Setting";
import { CreateFile } from "@/libs/Api/Media";
import { CreateNewIndexSlider } from "@/libs/Api/IndexSlider";
import Helper from "@/libs/Helper";
import SliderImageModal from "@/views/components/utils/SliderImageModal.vue";
import {
  BContainer,
  BPagination,
  BFormRow,
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BTable,
  BMediaAside,
  BMediaBody,
  BForm,
  BFormCheckbox,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BBadge,
  BFormTextarea,
  BOverlay,
  BFormSelect,
  BModal,
} from "bootstrap-vue";
</script>
<style>
.selected-svg > svg {
  filter: invert(60%) sepia(72%) saturate(4741%) hue-rotate(81deg)
    brightness(96%) contrast(91%);
}
</style>
